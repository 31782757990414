import { isCrossPly, LoadIndexType, SpecialtyTyreDto } from "@oaktyres/model";
import { fromUnixTime, isToday, isTomorrow } from "date-fns";
import { groupBy, uniq, uniqBy, sortBy } from "lodash";
import { FilterSet } from "./Filters";

const deliveryFilters: FilterSet<SpecialtyTyreDto> = {
  Availability: {
    Today: (x, q = 1) =>
      x.availability
        .filter(
          (x) =>
            !x.thirdParty &&
            !x.orderedStock &&
            isToday(fromUnixTime(x.dispatch)),
        )
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => acc + val, 0) >= q,
    "Next Van": (x, q = 1) =>
      x.availability
        .filter((x) => x.isNextRun)
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => val + acc, 0) >= q,
    "By Tomorrow": (x, q = 1) =>
      x.availability
        .filter(
          (x) =>
            !x.thirdParty &&
            !x.orderedStock &&
            (isToday(fromUnixTime(x.dispatch)) ||
              isTomorrow(fromUnixTime(x.dispatch))),
        )
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => val + acc, 0) >= q,
  },
};

const collectionFilters: FilterSet<SpecialtyTyreDto> = {
  Availability: {
    "Collect Now": (x, q = 1) =>
      x.collectionAvailability
        .filter((x) => x.availableNow)
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => acc + val, 0) >= q,
    "Collect Tomorrow": (x, q = 1) =>
      x.collectionAvailability
        .map((x) => x.qtyAvailable)
        .reduce((acc, val) => val + acc, 0) >= q,
  },
};

const filters: FilterSet<SpecialtyTyreDto> = {
  Construction: {
    Radial: (x) => !isCrossPly(x.fitment?.construction),
    "Cross-Ply": (x) => isCrossPly(x.fitment?.construction),
  },
  "Ply Rating": {},
  "Brand Tier": {
    Premium: (x) => x.brand.tier === 2,
    "Mid-Range": (x) => x.brand.tier === 1,
    Budget: (x) => x.brand.tier === 0,
    Unknown: (x) => x.brand.tier == null,
  },
  ["Load Index"]: {},
  "Tube Type": {
    Tubeless: (x) => x.tubeless,
    "Tube Type": (x) => !x.tubeless,
  },
  "E Mark": {
    "E Marked": (x) => x.eMark,
    "Not E Marked": (x) => !x.eMark,
  },
  Aramid: {
    Aramid: (x) => x.aramid != null,
    "Not Aramid": (x) => x.aramid == null,
  },
};

export const buildSpecialtyFilterSetFromData = (
  data: SpecialtyTyreDto[],
  supplyMode: "collection" | "delivery",
): FilterSet<SpecialtyTyreDto> => {
  const ff = {
    ...(supplyMode === "collection" ? collectionFilters : deliveryFilters),
    ...filters,
  };

  ff["Ply Rating"] = Object.fromEntries(
    uniq(data.map((x) => x.ply))
      .filter((x) => x != null)
      .sort((a, b) => a - b)
      .map((ply) => [`${ply} PR`, (f: SpecialtyTyreDto) => f.ply === ply]),
  );

  ff["Speed"] = Object.fromEntries(
    uniq(data.map((x) => x.speed))
      .sort()
      .map((x) => [x ? x : "<Unknown>", (y) => y.speed === x]),
  );

  ff["Pattern"] = Object.fromEntries(
    sortBy(
      uniqBy(
        data.map((x) => x.pattern),
        (x) => x.crossReference,
      ),
      (x) => x.name,
    ).map((x) => [
      x.name,
      (y) => y.pattern.crossReference === x.crossReference,
    ]),
  );

  ff["Manufacturer"] = Object.fromEntries(
    sortBy(
      uniqBy(
        data.map((x) => x.brand),
        (x) => x.crossReference,
      ),
    ).map((x) => [x.name, (y) => y.brand.crossReference === x.crossReference]),
  );

  const loads = groupBy(data, (x) => x.load);

  const loadNames: Record<LoadIndexType, string> = {
    standard: "(Std Load)",
    extra: "(Extra Load)",
    high: "(High Load)",
    unknown: "",
  };

  /*
  ff["Load Index"] = Object.fromEntries(
    Object.values(loads).map((items) => [
      `${items[0].load} ${loadNames[items[0].loadIndexType]}`,
      (f: SpecialtyTyreDto) => f.load === items[0].load,
    ]),
  );
  */

  return ff;
};
